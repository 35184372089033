import MotifModal, {
  MotifModalHeader,
  MotifModalBody,
} from "@ey-xd/motif-react/Modal";
import { MotifModalFooter } from "@ey-xd/motif-react";
import MotifButton from "@ey-xd/motif-react/Button";
import React, { useState } from "react";
import "./DocumentDeleteConfirmModal.css";
export function DocumentDeleteConfirmModal({
  setOpenModal,
  setConfirm,
  documentUUID,
  documentName,
}) {
  const [open, setOpen] = useState(true);

  const onCloseClick = () => {
    setOpen(false);
    setOpenModal(false);
  };

  return (
    <div>
      <MotifModal
        id="closepopupmodal"
        show={open}
        focusTrapOptions={{
          tabbableOptions: {
            displayCheck: "none",
          },
        }}
        onClose={() => {
          onCloseClick();
        }}
      >
        <MotifModalHeader
          closeButtonProps={{
            "aria-label": "Custom Close Button aria-label",
            title: "Custom Close Button title",
          }}
        >
          Delete Document
        </MotifModalHeader>
        <MotifModalBody>
          <React.Fragment key=".0">
            <div id="text">
              <span>
                Are you sure want to delete this document '{documentName}'?
              </span>
            </div>
            {/* <TestDropdown /> */}
          </React.Fragment>
        </MotifModalBody>
        <MotifModalFooter>
          <React.Fragment key=".0">
            <MotifButton
              onClick={() => {
                setConfirm(documentUUID, documentName);
              }}
              size="medium"
              type="button"
            >
              Yes
            </MotifButton>
            <MotifButton
              onClick={() => {
                onCloseClick();
              }}
              size="medium"
              type="button"
              variant="secondary"
            >
              No
            </MotifButton>
          </React.Fragment>
        </MotifModalFooter>
      </MotifModal>
    </div>
  );
}

export default DocumentDeleteConfirmModal;
