import {
  createContext,
  useEffect,
  useState,
  useContext,
  useRef,
  Fragment,
  useCallback
} from "react";
import { useNavigate,useOutletContext } from 'react-router-dom';
import freepik from "../../assets/images/byod-images/freepik.svg";
import freepik1 from "../../assets/images/byod-images/freepik1.svg";
import freepik2 from "../../assets/images/byod-images/freepik2.svg";
import {byodSendImg} from "../../../src/commonImage";
import dashboardnav from "../../assets/images/byod-images/dashboardnav.svg";
import {
  setByodDocOpenClose,
  setSurveyDocOpenClose
} from "../../store/actions/commonActions";
import{  saveUserDocumentThreadHistory,setLastDeletedThreadID} from "../../store/actions/userChatActions";
import { v4 as uuidv4 } from "uuid";
import _, { set } from "lodash";
import {
  MotifCheckbox,
  MotifSearch,
  MotifFormField,
  MotifPaginationSelect,
  MotifPaginationSelectItem,
  MotifButton,
  MotifBreadcrumb,
  MotifBreadcrumbItem,
  MotifInput,
  MotifTextArea 
} from "@ey-xd/motif-react";
import { useDispatch } from "react-redux";
import MotifToast from "@ey-xd/motif-react/components/toast";
import "./ExploreData.css";
import "./ProjectDashboard.css";
import ExloreRightPanal from "./ExploreDataRightPanal";
import ExplorePhase from "./ExplorePhase";
import { DashboardContext } from "../../context/dashboardContext";
import DocumentService from "../../services/document.service";
import { Link, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { IStore } from "../../store";
import { explorerDataConstants } from "./constants.js";
import { setSideBarOpenClose,setRightSideBarOpenClose } from "../../store/actions/commonActions";
import Breadcrumb from '../common/breadcrumb/Breadcrumb';
import { REACT_APP_POLLING_TIME_IN_SECOND } from "../../app.constant";
import SuggestionText from "../common/errorPages/ErrorSuggestion";
import AIDisclaimerText from "../sideBarComponent/AIDisclaimerText";
const ExploretData = () => {
  const exploreQuestionRef = useRef(null);
  const location = useLocation();
  // const defaultAskQuestion = location.state?.promptText ?? "";
  const documentservice = new DocumentService();
  // const [askQuestion, setAskQuestion] = useState(defaultAskQuestion);
  const [askQuestion, setAskQuestion] = useState('');
  const [questions, setQuestions] = useState([]);
  const [confPublic, SetConfPublic] = useState("C");
  const [publicClick, setPublicClick] = useState(false);
  const [confClick, setconfClick] = useState(true);
  const [isSubmitpromt, setIsSubmitPrompt] = useState(false);
  const [showPromptHistory, setShowPromptHistory] = useState(false);
  const[showDocuments, setShowDocuments] = useState(false);
  const showDocumentsRef = useRef(false);
  const projectName = sessionStorage.getItem("DashboardProjectName");
  const isRightSideBarOpen = useSelector((state: IStore) => state.common.isRightSideBarOpen); 
  const isSideBarOpen = useSelector((state: IStore) => state.common.isSideBarOpen);
  const isByodOpen = useSelector((state: IStore) => state.common.isByodOpen);
  const [isByodSourceDocsOpen, setIsByodSourceDocsOpen] = useState(false);
  const [isInterSideBarOpen, setIsInterSideBarOpen] = useState(false);
  const documentThreads = useSelector((state: IStore) => state.userChat.documentThreadHistory);
  const lastDeletedThreadID = useSelector((state: IStore) => state?.userChat?.lastDeletedThreadID);
  const selectedDocumentThread = useSelector(
    (state: IStore) => state.userChat.selectedDocumentThread
  );
  const userEmailId = useSelector((state: IStore) => state.common.userEmail);
  // 
  const dispatch = useDispatch();
const {
  isDocumentUploadProcessInitiated,
  setIsDocumentUploadProcessInitiated,
  showDocumentUploadMessage,
  setShowDocumentUploadMessage,
  viewPrompt,
  isNewPromptCoversation,
  setIsNewPromptCoversation,
} = useContext(DashboardContext);
const redirectTo = useNavigate();
const [showUploadComplete, setShowUploadComplete] = useState(false);
const [showUploadInPorgress, setShowUploadInPorgress] = useState(false);
const [selectedSourceDocs, setSelectedSourceDocs] = useState("");
const[checkedSourceDocs, setCheckedSourceDocs] = useState([]);
const [isUploadInProgressDisplayed, setIsUploadInProgressDisplayed] = useState(false);
const[isDocsButtonDisabled, setIsDocsButtonDisabled] = useState(false);
const [isResonseLoading, setIsResonseLoading] = useState(false);
const highlightExploreDataNavigationOption = useOutletContext();
const refToScroll = useRef(null);
const [srcDocDrpDwnItems, setSrcDocDrpDwnItems] = useState(
  explorerDataConstants.Types
);
const TEXT_HEIGHT = askQuestion.length > 150 ? 60 : 44;
  useEffect(()=> {
  if(!selectedDocumentThread?.threadId){
    redirectTo('/uploadPage')
    }else{
      setIsByodSourceDocsOpen(true);
      setShowDocuments(true);
      dispatch(setSideBarOpenClose(false));
    }
    dispatch(setSurveyDocOpenClose(false));
    setQuestions([]);
    setAskQuestion('');
    dispatch(setSideBarOpenClose(true));
    setIsDocsButtonDisabled(false);
    setCheckedSourceDocs([]);
    setSrcDocDrpDwnItems(explorerDataConstants.Types);
  },[selectedDocumentThread])
  useEffect(()=> {
    return () => {
      if(location.state?.promptText !== undefined) {
        location.state.promptText = "";
      }
    }
  },[])
useEffect(() => {
  dispatch(setByodDocOpenClose(showDocuments))
  setIsByodSourceDocsOpen(showDocuments);
  showDocuments && setIsInterSideBarOpen(false);
  showDocuments?dispatch(setSideBarOpenClose(false)):"";
},[showDocuments,isSideBarOpen,isByodOpen,isRightSideBarOpen]);
  useEffect(()=>{
    if(location.state?.promptText !== "" && location.state?.promptText!== undefined) {
      setAskQuestion(location.state?.promptText);
      document.getElementById('question')?.focus();
      highlightExploreDataNavigationOption();
    }
    if(isSubmitpromt===true){
      setAskQuestion('');
    }
  }, [questions]);

  useEffect(() => {
    if (viewPrompt && viewPrompt?.requestContent) {
      let quests = [];
      quests.push(viewPrompt.requestContent);
      setQuestions(quests);
      //setQuestions([...questions, viewPrompt.requestContent]);
      setAskQuestion("");
      setShowPromptHistory(true);
      if (viewPrompt?.isPublicConfidential == "C") {
        SetConfPublic("C");
        setPublicClick(false);
        setconfClick(true);
      } else {
        SetConfPublic("P");
        setPublicClick(true);
        setconfClick(false);
      }
    }
  }, [viewPrompt]);

  useEffect(() => {
    if (isNewPromptCoversation) {
      setQuestions([]);
      setIsNewPromptCoversation(false);
    }
  }, [isNewPromptCoversation]);

  useEffect(() => {
    if (showDocumentUploadMessage && isUploadInProgressDisplayed) {
      setIsUploadInProgressDisplayed(false);
      setShowUploadInPorgress(true);
    }
  }, [showDocumentUploadMessage]);

  useEffect(() => {
    if (isDocumentUploadProcessInitiated) {
      let interval = setInterval(async () => {
        const uploadDocuments = await documentservice.getDocuments(
          selectedDocumentThread?.threadId,selectedDocumentThread?.title,userEmailId
        );
        if (uploadDocuments.length > 0) {
          const notCompletedDocs = _.filter(uploadDocuments, function (item) {
            return item.status !== "Complete";
          });
          if (notCompletedDocs.length > 0) {
            if (!showDocumentUploadMessage) {
              setShowDocumentUploadMessage(true);
              setIsUploadInProgressDisplayed(true);
            }
          } else {
            setShowUploadComplete(true);
            setTimeout(() => {
              setShowUploadComplete(false);
            },3000)
            setIsDocumentUploadProcessInitiated(false);
            clearInterval(interval);
          }
        }
      },1000 * parseInt(REACT_APP_POLLING_TIME_IN_SECOND));
      return () => {
        clearInterval(interval);
      };
    }
  }, [isDocumentUploadProcessInitiated]);

  useEffect(() => {
    if (lastDeletedThreadID != 0) {
      const updated = documentThreads.filter((item: any) => item.threadId !== lastDeletedThreadID);
      dispatch(saveUserDocumentThreadHistory(updated));
      dispatch(setLastDeletedThreadID(0));
    }
  }, [lastDeletedThreadID,documentThreads]);

  const OnChangeQuestion = (event) => {
    event.preventDefault();
    setAskQuestion(event.target.value);
    setIsSubmitPrompt(false);
  };
  const handleFocusQuestion = (event) => {
    setShowDocuments(true);
    showDocumentsRef.current = true;
    setIsByodSourceDocsOpen(showDocuments);
    dispatch(setByodDocOpenClose(showDocuments))
    dispatch(setSideBarOpenClose(false))
    setIsInterSideBarOpen(false);
  }
  const isElementsDisabled = (val) => {
    setIsDocsButtonDisabled(false);
    setTimeout(() => {
      scrollToBottom();
    }, 300);
  }
  const selectedDropDowndocs = (item) => {
    setSrcDocDrpDwnItems(item);
  }

// exploreQuestion && exploreQuestion.addEventListener("keydown", (event) => {
//   if (event.isComposing || event.keyCode === 13) {
//     event.preventDefault();
//     event.target.closest(".explore-data-container-prompt-question-container").querySelector(".explore-data-container-sendimage").click();
//     //onSendPrompt();
//   }
// });


const handleKeyDown = useCallback((event) => {
  if (event.isComposing || event.keyCode === 13 || event.key === 'Enter') {
    event.preventDefault();
    const sendButton = event.target.closest(".explore-data-container-prompt-question-container").querySelector(".explore-data-container-sendimage");
    if (sendButton) {
      sendButton.click();
    }
  }
}, []);

useEffect(() => {
  const exploreQuestion = exploreQuestionRef.current;
  if (exploreQuestion) {
    exploreQuestion.addEventListener("keydown", handleKeyDown);
  }

  return () => {
    if (exploreQuestion) {
      exploreQuestion.removeEventListener("keydown", handleKeyDown);
    }
  };
}, [handleKeyDown]);

  const onSendPrompt = () => {
    setIsResonseLoading(true);
    setIsDocsButtonDisabled(true);
    if (askQuestion !== "") {
      if (questions.length === 0) {
        if (sessionStorage.getItem("PromptConversationId")) {
          sessionStorage.removeItem("PromptConversationId");
        }
        sessionStorage.setItem("PromptConversationId", uuidv4());
      }
      if (showPromptHistory) {
        setShowPromptHistory(false);
      }
      setQuestions([...questions, askQuestion]);
      setAskQuestion("");
      setIsSubmitPrompt(true);
    }
    setTimeout(() => {
      document.querySelectorAll("#question")[0].focus();
    },200);
  };
  const AddPromptToQuestionBar = (EditPrompt = String) => {
    setAskQuestion(EditPrompt);
  };

  function selctedSourceDocs(selectdocs) {
    setCheckedSourceDocs(selectdocs);
    let resultitems = selectdocs?.filter((item) => {
      return item?.selected;
    });
    let docnames = "";
    let docarray = [];
    if (resultitems?.length > 0) {
      resultitems.map((item) => {
        docarray.push(item.documentName);
      });
      docnames += docarray.toString();
    }
    setSelectedSourceDocs(docnames);
  }
  const breadcrumbs = [{ label: 'Home' }, { label: 'Upload Document and Query', current: true }];

  const scrollToBottom = () => {
    refToScroll?.current?.scroll({
      left: 0,
      top: refToScroll.current.scrollHeight,
      behavior: 'smooth'
    });
  };

  const isResponseLoadingFunc = () => {
    setIsResonseLoading(false);
  }

  return (
    <div className={`${isRightSideBarOpen? "exploredata-open":"exploredata-close"} exploreDataContainer ${isSideBarOpen||isInterSideBarOpen?"datasourcebar-open":"datasourcebar-close"} ${isByodOpen||isByodSourceDocsOpen?"sourcedocs-open":"sourcedocs-close"}`}>
      {/* <ExploreContextProvider> */}
      <div className={isRightSideBarOpen ? 'breadcrumbOpen' : 'breadcrumb'}>
          <Breadcrumb items={breadcrumbs} />
        </div>
      <div className="explore-data-text">Explore your data</div>
     <div className="hideview-docs manage-docs">
     <MotifButton
                 disabled={isDocsButtonDisabled}
                className={
                    "confpublic ActivePublic manage-docs-button"
                }
                onClick={() => {
                  dispatch(setByodDocOpenClose(false));
                  setIsByodSourceDocsOpen(false);
                  redirectTo('/uploadPage');
                  
                  //showDocuments?setSideBarOpenClose(true):setSideBarOpenClose(false);
                }}
              >Manage documents</MotifButton>
      <MotifButton
                disabled={isDocsButtonDisabled}
                className={
                    "confpublic ActivePublic view-hide-docs"
                }
                onClick={() => {
                  setShowDocuments(!showDocuments);
                  showDocumentsRef.current = !showDocuments;
                  showDocumentsRef.current && setIsInterSideBarOpen(false);
                  showDocumentsRef.current?dispatch(setSideBarOpenClose(false)):"";
                  showDocumentsRef.current?setIsByodSourceDocsOpen(true):setIsByodSourceDocsOpen(false);
                }}
              >{showDocuments?'Hide source documents':'View source documents'}</MotifButton>
      </div>
       
      {(showDocuments && <div className="explore-data-right-panel">
        <ExloreRightPanal
          publicOrConfidential={confPublic}
          selectdocs={selctedSourceDocs}
          checkedSourceDocs={checkedSourceDocs}
          selectedDropDowndocs={selectedDropDowndocs}
          checkedDropdownDocs={srcDocDrpDwnItems}
        />
      </div>)}

      <div className={`explore-data-container ${ !showDocuments?"hidedocuments":"visibledocuments"}`}>
        <div ref={refToScroll} className="explore-data-container-child">
          {questions.length === 0 && (
            <div className="explore-data-container-without-quest">
              <div className="explore-data-container-image-container">
                <img src={freepik} alt="icon" />
                <div className="explore-data-container-image-container-child">
                  <div className="explore-data-container-image-container-text">
                    I am here to help you
                  </div>
                  <div className="explore-data-container-image-container-tagline">
                  </div>
                </div>
              </div>
            </div>
          )}

          {questions.length > 0 &&
            questions.map((question,index) => {
              return (
                <div key={index} className="explore-data-container-with-quest">
                  <ExplorePhase
                    isResponseLoadingFunc={isResponseLoadingFunc}
                    question={question}
                    publicConf={confPublic}
                    addprompt={AddPromptToQuestionBar}
                    isPromptSubmit={isSubmitpromt}
                    showPromotHistory={showPromptHistory}
                    selectedDocNames={selectedSourceDocs}
                    elementsDisabled={isElementsDisabled}
                  />
                </div>
              );
            })}
          <div className="explore-data-container-promptarea-container">
            {/* <div className="explore-data-container-promptarea-buttons">
              <MotifButton
                className={
                  confClick ? "confpublic ActivePublic " : "ActiveConf"
                }
                onClick={() => {
                  SetConfPublic("C");
                  setPublicClick(false);
                  setconfClick(true);
                }}
              >
                Confidential
              </MotifButton>

              <MotifButton
                className={
                  publicClick
                    ? "confpublic ActivePublic  marginpublic"
                    : "ActiveConf marginpublic"
                }
                onClick={() => {
                  SetConfPublic("P");
                  setPublicClick(true);
                  setconfClick(false);
                }}
              >
                Public
              </MotifButton>
            </div> */}

            <div className="explore-data-container-prompt-question-container">
              <MotifFormField className={`explore-data-contianer-form-field explore-field-text-area-input`}>
                <textarea
                  id="question"
                  ref={exploreQuestionRef}
                  onChange={(e) => {
                    OnChangeQuestion(e);
                  }}
                  onFocus={handleFocusQuestion}
                  // onSelect={function noRefCheck() { }}
                  placeholder="Ask a question"
                  value={askQuestion}
                  className={`inputcontainer_${TEXT_HEIGHT} ${isResonseLoading && "cursor-disabled"}`}
                  maxLength={1000}
                  disabled={isResonseLoading ? true : false}
                />
              </MotifFormField>
              <div className={`explore-data-container-prompt-question-container-child buttonimage_${TEXT_HEIGHT}`}>
                <MotifButton
                  className={`explore-data-container-sendimage ${isResonseLoading && "cursor-disabled"}`}
                  onClick={onSendPrompt}
                >
                  <img src={byodSendImg} alt ="submit icon" />
                </MotifButton>
              </div>

              <div className="explore-data-container-response-note">
               <AIDisclaimerText hideHeadingText = {true}/>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      {showUploadInPorgress && (
        <MotifToast
        className="documents-ready"
          variant="info"
          actionName=""
          position="bottom"
          onClose={() => {
            setShowUploadInPorgress(false);
          }}
        >
          <div>
            Upload complete. Getting your documents ready for prompt. You can
            now exit Competitive Edge.
          </div>
        </MotifToast>
      )}
      {showUploadComplete && (
        <MotifToast
          variant="success"
          actionName=""
          position="bottom"
          onClose={() => {
            setShowUploadComplete(false);
          }}
        >
          <div>
            Upload complete. Getting your documents ready for prompt. You can
            now exit Competitive Edge.
          </div>
        </MotifToast>
      )}
      {/* </ExploreContextProvider> */}
    </div>
  );
};

export default ExploretData;
