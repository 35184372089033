import {
  MotifErrorMessage,
  MotifFileUploader,
  MotifFooter,
  MotifFormField,
  MotifInput,
  MotifSearch,
  MotifPaginationSelect,
  MotifPaginationSelectItem,
} from "@ey-xd/motif-react";
import { useDispatch,useSelector } from "react-redux";
import { IStore } from "../../store";
import MotifInlineMessage from "@ey-xd/motif-react/InlineMessage";
import MotifToast from "@ey-xd/motif-react/Toast";
import {
  ProviderState,
  Providers,
  SimpleProvider,
} from "@microsoft/mgt-element";
import React, { useEffect, useRef, useState, useContext } from "react";
import LoaderComponent from "../motifComponents/LoaderComponent";
import MotifModal, {
  MotifModalBody,
  MotifModalHeader,
} from "@ey-xd/motif-react/Modal";

import MotifButton from "@ey-xd/motif-react/Button";
import _ from "lodash";
import { DashboardContext } from "../../context/dashboardContext";
import MotifTable, { MotifTableTitleBar } from "@ey-xd/motif-react/Table";
import "./AddDocument.css";
// import '../CreateProject/modalPopup.css';
import page from "../../assets/images/byod-images/page.svg";
import link from "../../assets/images/byod-images/link.svg";
import DocumentService from "../../services/document.service";
import GraphService from "../../services/graphService";
import Utils from "../../utils/Utils";
import searchIcon from "../../assets/images/byod-images/search.svg";
import documentApiRoutes from '../../services/api';
import { handleDocumentThreadHistory, isTeamsApp } from "../../utils/commonUtils";
import {
  saveUserDocumentThreadHistory
} from '../../store/actions/userChatActions';
import { DataSourceTypes } from '../../models/IDataSourceTypes';
import errorStatus from "../../utils/ErrorStatus";
import { customErrorStatus } from "../../services/userProfile.service";
import {byodUploadIcon} from "../../../src/commonImage";
import {getFirstFolderName} from "../../utils/commonUtils";

const UploadModal = ({
  openShow,
  setOpenModal,
  filescountInfo,
  localFileUploadSuccess,
  sharePointFileUploadSuccess,
}) => {
 const graphService = new GraphService();
 const dispatch = useDispatch();
  const utils = new Utils();
  const createdFileDate= utils.customformattedDate(new Date());
  const selectedDocumentThread = useSelector(
    (state: IStore) => state.userChat.selectedDocumentThread
  );
  const userData = useSelector((state: IStore) => state.userProfile?.userData);
  const userMailID = useSelector((state: IStore) => state.common?.userEmail);
  const documentThreads = useSelector((state: IStore) => state.userChat.documentThreadHistory);
  const isRightSideBarOpen = useSelector((state: IStore) => state.common.isRightSideBarOpen);
  const documentservice = new DocumentService();
  const isSideBarOpen = useSelector((state: IStore) => state.common.isSideBarOpen);

  const urlRegex =
    /^(https?:\/\/)?([^/\s]+\/)([^/\s]+\/)([^/\s]+\/)([^/\s]+)(.*)/;

  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFileTemp, setSelectedFileTemp] = useState([]);
  const [SearchLocal, setSearchLocal] = useState("");
  const [pagesize, SetPageSize] = useState(5);

  const [open, setOpen] = useState(openShow);
  const [openFilesGrid, setOpneFilesGrid] = useState(false);
  const [errorfile, setErrorFile] = useState(false);
  const [filecountLimit, setFileCountLimit] = useState(0);
  const [errorFilescount, setErrorFilesCount] = useState(false);
  const [totalFiles, setTotalFiles] = useState(0);
  const MaxFileFlimit = useState(filescountInfo.MaxFilesuploadLimit);
  const [isUploadSubmitted, setIsUploadSubmitted] = useState(false);
  const [MaxFileFlimitSize] = useState(filescountInfo.MaxFFileSizeLimit);
  const [fileUploadLimit, setFileUploadLimit] = useState(0);

  const [totalFilesSize, setTotalFilesSize] = useState(0);
  const [errorFilseSizeCount, seterrorFileSizeCount] = useState(false);
  const [extrasize, setExtraSize] = useState(0);
  const [errorCharacters, setErrorCharacters] = useState(false);

  const [sharePointSiteUrl, setSharePointSiteUrl] = useState("");
  const [isInValidSiteUrl, setIsInValidSiteUrl] = useState(false);
  const [isInValidSharePointSite, setIsInValidSharePointSite] = useState(false);
  const [isInValidSitePath, setIsInValidSitePath] = useState(false);
  const [showSharePointModal, setShowSharePointModal] = useState(false);
  const [siteName, setSiteName] = useState("");
  const [sitePath, setSitePath] = useState("");
  const [siteDomainName, setSiteDomainName] = useState("");
  const siteNameRef = useRef('');
  const sitePathRef = useRef('');
  const siteDomainNameRef = useRef('');
  const [showDirectory, setShowDirectory] = useState(false);
  const [siteId, setSiteId] = useState("");
  const siteIdRef = useRef('');
  const [sharePointFiles, setSharePointFiles] = useState([]);
  const [selectedSharePointFiles, setSelectedSharePointFiles] = useState([]);
  const [sharePointFilesMemory, setSharePointFilesInMemory] = useState([]);
  const sharePointFilesRef = useRef({});
  sharePointFilesRef.current = sharePointFiles;
  const [siteItem, setSiteItem] = useState("");
  const [sharePointConfirmButtonEnabled, setSharePointConfirmButtonEnabled] =
    useState(true);
  const [sharePointSearch, setSharePointSearch] = useState("");
  const [isSharePointValidLink, setIsSharePointValidLink] = useState(false);
  let selctedFilenames = [];
  const [checkListSelect, setCheckListSelect] = useState([]);
  const [errrduplicate, setErrorDuplicate] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const {
    isDocumentUploadProcessInitiated,
    setIsDocumentUploadProcessInitiated,
  } = useContext(DashboardContext);
  const [spFileValidation, setspFileValidation] = useState(false);
  const[spFileFormatValidation,setspFileFormatValidation]=useState(true);
  const [perFileLimitExceed, setPerFileLimitExceed] = useState(false);
  const [spdupNameValidation, setspdupNameValidation] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  let chkFileValid = false;

  useEffect(() => {
    setOpenModal(openShow);
  }, [openShow,setOpenModal]);

  
  useEffect(() => {
    console.log(filescountInfo);
    setFileUploadLimit(
      utils.getBytesFromGB(filescountInfo.MaxFFileSizeLimit) /
        filescountInfo.MaxFilesuploadLimit
    );
    getAccessToken();
  }, []);
 
  const getAccessToken = async () => {
    const graphService = new GraphService();
    const graphApiToken = await graphService.getToken();
    setAccessToken(graphApiToken);
  };

  useEffect(() => {
    setSharePointConfirmButtonEnabled(
      spFileValidation || spdupNameValidation || perFileLimitExceed ||!spFileFormatValidation
    );
  }, [spFileValidation, spdupNameValidation, perFileLimitExceed,spFileFormatValidation]);

  useEffect(() => {
    if ((siteDomainName && siteName) || (siteDomainNameRef.current&&siteNameRef.current)) {
      fetchSharePointDetails();
    }
  }, [siteDomainName,siteName,siteDomainNameRef.current,siteNameRef.current]);

  useEffect(() => {
    if ((siteId && sitePath) || (siteIdRef.current&&sitePathRef.current)) {
      fetchSharepointDirectory();
    }
  }, [siteId, sitePath]);
  const changeSiteUrlValue = (value) => {
    //setSharePointSiteUrl(value);
    let sURL=value?.replace("/:x:\/r/", "/");
    sURL = sURL?.replace("/:f:\/r/", "/");
    sURL = sURL?.replace("/:p:\/r/", "/");
    sURL = sURL?.replace("/:w:\/r/", "/");
    sURL = sURL?.replace("/:t:\/r/", "/");
    sURL = sURL?.replace("/:b:\/r/", "/");
    sURL = sURL?.slice(0,sURL.indexOf('?'));
    setSharePointSiteUrl(sURL);
    setIsInValidSiteUrl(false);
    setIsInValidSharePointSite(false);
    setIsInValidSitePath(false);
  };

  // this if for selected files table
  let filesdata = [];
  const [colDefs] = useState([
    {
      field: "",
      headerCheckboxSelection: true,
      // width: 10,
      checkboxSelection: true,
    },
    {
      field: "name",
      displayName: "Files",
      width: 400,
      cellRenderer: (params) => (
        <div>
          <img src={page} alt={params.data.name} /> {params.data.name}
        </div>
      ),
    },
    {
      field: "size",
      displayName: "File size",
      cellRenderer: (params) => (
        <div>{utils.getBytesInMB(params.data.size)} MB</div>
      ),
    },
  ]);

  const [colDefsSharePointGrid] = useState([
    {
      field: "",
      headerName: "",
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      field: "name",
      displayName: "Files",
      width: 400,
      cellRenderer: (params) => (
        <div>
          <img src={page} alt={params.data.name} /> {params.data.name}
        </div>
      ),
    },
    {
      field: "size",
      displayName: "File size",
      width: 200,
      cellRenderer: (params) => (
        <div>{utils.getBytesInMB(params.data.size)} MB</div>
      ),
    },
  ]);
  // checkbox checked if any file having the validation before displaying in the grid
  function onGridReady(event) {
    event.api.forEachNode(function (node) {
      if (
        node.data.error != "" ||
        (node.data.errorduplicate != undefined &&
          node.data.errorduplicate != "") ||
        (node.data.errorSplChar != undefined && node.data.errorSplChar != "")||
        (node.data.errorPerFileLimitExceed !== undefined &&
          node.data.errorPerFileLimitExceed !== "")
      ) {
        node.setSelected(true);
      } else {
        node.setSelected(false);
      }
    });
}
  // settingup the selected files
  function onRowSelection(event) {
    let selectedFiles = event.api.getSelectedRows();
    setCheckListSelect(selectedFiles);
  }
  // removing the selected files
  function removeSelectedItems() {
    if (checkListSelect.length > 0) {
      const restitem = [...selectedFile];
      let validtioncheck = false;
      let removedItems = [];
      for (const f of checkListSelect) {
        let index = restitem.findIndex((x) => x.name === f.name);
        if (index >= 0) {
          restitem.splice(index, 1);
          validtioncheck = true;
          removedItems.push(f);
        }
      }

      setSelectedFile(restitem);
      setSelectedFileTemp(restitem);
      if (validtioncheck) checkValidationrequried(restitem, removedItems);
      selctedFilenames = [];
    }
  }
  // verifying validation  for the rest of the files after removing the files
  function checkValidationrequried(remainFiles, removedItems) {
    let error = false;
    let errordup = false;
    let fileremainSize = 0;
    let errorChar = false;
    let errorPerFileUploadExceed = false;
    remainFiles.forEach((item) => {
      fileremainSize += parseInt(item.size);
      if (item.error !== "") error = true;
      if (item.errorduplicate !== undefined && item.errorduplicate !== "")
        errordup = true;
      if (item.errorSplChar !== undefined && item.errorSplChar !== "")
        errorChar = true;
        if (
          item.errorPerFileLimitExceed !== undefined &&
          item.errorPerFileLimitExceed !== ""
        )
          errorPerFileUploadExceed = true;
    });
    setErrorFile(error);
    setErrorDuplicate(errordup);
    setErrorCharacters(errorChar);
    // let filesremain = 0;
    setPerFileLimitExceed(errorPerFileUploadExceed);
    showFilecount(filescountInfo?.filesuploadcount, remainFiles);

    fileremainSize += filescountInfo?.totalFileSize;

    let resultfilesize = utils.getBytesInMB(fileremainSize);

    setTotalFilesSize(resultfilesize);

    checkExtraFiles(filescountInfo?.avialbalefileuplaod, remainFiles);

    ExtraFileSize(filescountInfo?.availableFilesizeLimit, fileremainSize);
  }
  // setting up the total files to display
  function showFilecount(fileuploadcount, remainFiles) {
    let totaluploadscount = fileuploadcount + remainFiles.length;
    setTotalFiles(totaluploadscount);
  }
  // setting up the extra files selected with the size of them and showing that extra size
  function ExtraFileSize(availableFileSize, fileRemainedSize) {
    if (availableFileSize < fileRemainedSize) {
      seterrorFileSizeCount(true);
      let resfileSizecount = fileRemainedSize - availableFileSize;

      let ressize = utils.getBytesInMB(resfileSizecount);

      setExtraSize(ressize);
    } else {
      seterrorFileSizeCount(false);
      setExtraSize("");
    }
  }
  // files table End here
  // setting up the selected files ondrop file event and verifying the file validation
  const handleFileUpload = (event) => {
    //setSelectedFile(event);
    const allowedTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.ms-excel",
      "application/pdf",
      "text/plain",
      "application/msword",
      "application/mspaint",
    ];

    const uploaded = [...selectedFile];
    const chosenFiles = Array.prototype.slice.call(event);
    let error = false;
    let totalfilesSize = 0;
    selctedFilenames = [];
    chosenFiles.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        totalfilesSize = totalfilesSize + parseInt(file?.size);
        filescountInfo.uploadedFilesdata?.forEach((item) => {
          if (item.documentName === file.name) {
            setErrorDuplicate(true);

            file.errorduplicate = "Duplicate file uploading";
            selctedFilenames.push({
              name: file?.name,
              size: file?.size,
            });
            setCheckListSelect(selctedFilenames);
          }
        });
        if (!allowedTypes.includes(file?.type)) {
          setErrorFile(true);
          error = true;
          file.error = "Incorrect File Format ";
          selctedFilenames.push({
            name: file?.name,
            size: file?.size,
          });
          setCheckListSelect(selctedFilenames);
        } else file.error = "";

        let fileExtension = file?.name.lastIndexOf(
          utils.getFileExtension(file?.name)
        );
        let filestringName = file?.name
          .toString()
          .substring(0, fileExtension - 1);
        if (utils.isContainSpecialCharacters(filestringName)) {
          setErrorCharacters(true);
          file.errorSplChar = "Special Characters included in file Name";
          if (!error) {
            selctedFilenames.push({
              name: file?.name,
              size: file?.size,
            });
            setCheckListSelect(selctedFilenames);
          }
        }
        if (file?.size > fileUploadLimit) {
          setPerFileLimitExceed(true);
          file.errorPerFileLimitExceed = `Per file Limit exceeded (${utils.getBytesInMB(
            fileUploadLimit
          )} MB)`;
          if (!error) {
            selctedFilenames.push({
              name: file?.name,
              size: file?.size,
            });
            setCheckListSelect(selctedFilenames);
          }

        }
        uploaded.push(file);
      }
    });

    // if (!error) {
    setSelectedFile(uploaded);
    setSelectedFileTemp(uploaded);
    let resFiles = filescountInfo;

    showFilecount(resFiles?.filesuploadcount, uploaded);

    totalfilesSize += resFiles?.totalFileSize;

    let resultfilesize = utils.getBytesInMB(totalfilesSize);

    setTotalFilesSize(resultfilesize);

    checkExtraFiles(resFiles?.avialbalefileuplaod, uploaded);

    ExtraFileSize(resFiles?.availableFilesizeLimit, totalfilesSize);

    setOpen(false);
    setOpneFilesGrid(true);
    // }
  };

  // check the extra files selected and display
  function checkExtraFiles(availablefiles, slctedFiles) {
    if (availablefiles < slctedFiles?.length) {
      setErrorFilesCount(true);
      let resfilecount = slctedFiles?.length - availablefiles;
      setFileCountLimit(resfilecount);
    } else {
      setErrorFilesCount(false);
      setFileCountLimit("");
    }
  }

  // submitting or uploading the selected files
  async function onSumbitupload() {
    setIsUploadSubmitted(true);
    if (selectedFile.length > 0) {
      setShowLoader(true);
      if(selectedDocumentThread?.threadId)
      {
      const res = await documentservice.uploadLocalDocuments(selectedFile,selectedDocumentThread?.threadId,selectedDocumentThread?.title,userMailID,userData);
    //const res = { status: 200 };
    if (res.status == 200) {
      setIsDocumentUploadProcessInitiated(true);
      localFileUploadSuccess(true);
      setOpenModal(!openShow);
    } else {
      localFileUploadSuccess(false);
      setOpenModal(!openShow);
      // alert("something went wrong");
      // if (!res?.status) return customErrorStatus(res?.statusText);
      // return errorStatus(res?.status);
    }
      }
  else{
    const createfileName=selectedFile[0]?.name?.split(".")[0];
    const fullFilename=createfileName+createdFileDate;
    const createProjectResponse= await documentservice.createProject(userMailID,'General',fullFilename);
    if(createProjectResponse?.project_UUID){
      handleDocumentThreadHistory(fullFilename,createProjectResponse.project_UUID,documentThreads,dispatch);
    }
    const res = await documentservice.uploadLocalDocuments(selectedFile,createProjectResponse?.project_UUID,fullFilename,userMailID,userData);

    //const res = { status: 200 };
    if (res.status == 200) {
      setIsDocumentUploadProcessInitiated(true);
      localFileUploadSuccess(true);
      setOpenModal(!openShow);
    } else {
      localFileUploadSuccess(false);
      setOpenModal(!openShow);
      // alert("something went wrong");
      // if (!res?.status) return customErrorStatus(res?.statusText);
      // return errorStatus(res?.status);
     // alert("something went wrong");
    }
  
  }
  setShowLoader(false);
    }
    setIsUploadSubmitted(false);

  }
  // filter the local upload grid based on input
  const filterLocalFiles = (event) => {
    setSearchLocal(event.target.value);
    let value = event.target.value.toString().toLowerCase().trim();
    if (value !== "" && selectedFile.length > 0) {
      const count = colDefs.length;
      const keys = ["name"];
      let rows = [];
      rows.push(
        selectedFileTemp.filter((item) => {
          for (let i = 0; i < count; i++) {
            if (
              (item[keys[i]] &&
                item[keys[i]].toString().toLowerCase().indexOf(value) !== -1) ||
              !value
            ) {
              return true;
            }
          }
        })
      );
      setSelectedFile(rows[0]);
    } else {
      if (value === "") setSelectedFile(selectedFileTemp);
    }
  };

  const showSharePointView = () => {
    if (sharePointSiteUrl === "" || sharePointSiteUrl.indexOf("//people.ey.com/personal")>-1) {
      setIsInValidSiteUrl(true);
    } else {
      let matchedUrlItems = urlRegex.exec(sharePointSiteUrl);
      if (matchedUrlItems && matchedUrlItems.length > 0) {
        try {
          setSiteDomainName(matchedUrlItems[2]);
          siteDomainNameRef.current = matchedUrlItems[2];
          setSiteName(matchedUrlItems[3] + matchedUrlItems[4]);
          siteNameRef.current = matchedUrlItems[3] + matchedUrlItems[4];
          setSitePath(matchedUrlItems[matchedUrlItems.length - 1]);
          sitePathRef.current = matchedUrlItems[matchedUrlItems.length - 1];
        } catch {
          setIsInValidSiteUrl(true);
        }
      } else {
        setIsInValidSiteUrl(true);
      }
    }
  };

  const fetchSharePointDetails = async () => {
    const siteDetails = await graphService.getSharePointSiteDetails(
      siteDomainName || siteDomainNameRef.current,
      siteName || siteNameRef.current,
      userData
    );
    if (siteDetails) {
      if (siteDetails?.isError) {
        setIsInValidSharePointSite(true);
      } else {
        setSiteId(siteDetails.id);
        siteIdRef.current = siteDetails.id;
      }
    }
  };

  const fetchSharepointDirectory = async () => {
    const siteItemDetails = await graphService.getSharePointDocumentFiles(
      siteId || siteIdRef.current,
      sitePath || sitePathRef.current,
      userData
    );
    if (siteItemDetails) {
      if (siteItemDetails?.isError) {
        setIsInValidSitePath(true);
      } else {
        setIsSharePointValidLink(true);
        setTimeout(() => {
          setIsSharePointValidLink(false);
          if (siteItemDetails.value?.length > 0) {
            let result = _.chain(siteItemDetails?.value)
              .filter((item) => item.file)
              .map(function (element) {
                return {
                  id: element.id,
                  name: element.name,
                  size: element.size,
                  lastModifiedDateTime: element.lastModifiedDateTime,
                };
              })
              .value();
            setSharePointFiles(result);
            setSelectedFile(result);
            setSharePointFilesInMemory(result);
            setSiteItem(siteItemDetails.id);
            setOpen(false);
            setShowSharePointModal(true);
            setShowDirectory(true);
            setTotalFiles(filescountInfo?.filesuploadcount);
            setTotalFilesSize(
              utils.getBytesInMB(filescountInfo?.totalFileSize)
            );
          }
        }, 2000);
      }
    }
  };
  const filterSharePointFiles = (event) => {
    setSharePointSearch(event.target.value);
    let value = event.target.value.toString().toLowerCase().trim();
    if (value != "" && sharePointFiles.length > 0) {
      const count = colDefsSharePointGrid.length;
      const keys = ["name"];
      let rows = [];
      rows.push(
        sharePointFilesMemory.filter((item) => {
          for (let i = 0; i < count; i++) {
            if (
              (item[keys[i]] &&
                item[keys[i]].toString().toLowerCase().indexOf(value) !== -1) ||
              !value
            ) {
              return true;
            }
          }
        })
      );
      setSharePointFiles(rows[0]);
    } else {
      setSharePointFiles(sharePointFilesMemory);
    }
  };

   /**
   * Description
   * -----------
   * This method initiates the sharepoint upload process.
   */
   async function uploadSharePointDocument  () {
    let selectedDcoument = selectedSharePointFiles;
    setIsUploadSubmitted(true);
    if (selectedDcoument.length > 0) {
      setShowLoader(true);
      if(selectedDocumentThread?.threadId)
      {
           let selectedSharepointDocuments = [];
      selectedDcoument.forEach((item) => {
        selectedSharepointDocuments.push({
          id: item.id,
          name: item.name,
          size: item.size.toString(),
          lastModifiedDateTime: item.lastModifiedDateTime,
        });
      });
      let requestObject = {
        P_SharePointSite_ID: siteId,
        P_UserAccessToken: !isTeamsApp() ? accessToken : localStorage.getItem("sharePointTeamsToken") ,
        P_Blob_Container_Name: selectedDocumentThread?.threadId,
        P_Sharepoint_FileList: selectedSharepointDocuments,
        P_UploadUserEmail: localStorage.getItem("accountEmail"),
        P_UploadUserName: localStorage.getItem("accountName"),
      };

      // **** need to remove commented code after API call successfully implemented ****

      // documentservice.UploadDocumentToSharePoint(requestObject);
      setOpenModal(!openShow);
      sharePointFileUploadSuccess(true);
      // const res = await documentservice.uploadLocalDocuments(selectedDcoument,selectedDocumentThread?.threadId,selectedDocumentThread?.title,userMailID,userData);
      const res = await documentservice.UploadDocumentToSharePoint(requestObject,selectedDcoument,selectedDocumentThread?.threadId,selectedDocumentThread?.title,userMailID,userData);
    if (res.status == 200) {
      setIsDocumentUploadProcessInitiated(true);
      // localFileUploadSuccess(true);
      sharePointFileUploadSuccess(true);
      setOpenModal(!openShow);
    } else {
      // localFileUploadSuccess(false);
      sharePointFileUploadSuccess(false);
      setOpenModal(!openShow);
    }
      }
  else{
    const createfileName=selectedDcoument[0]?.name?.split(".")[0];
    const fullFilename=createfileName+createdFileDate;
    const createProjectResponse= await documentservice.createProject(userMailID,'General',fullFilename);
    if(createProjectResponse?.project_UUID){
      handleDocumentThreadHistory(fullFilename,createProjectResponse.project_UUID,documentThreads,dispatch);
    }
    let selectedSharepointDocuments = [];
    selectedDcoument.forEach((item) => {
      selectedSharepointDocuments.push({
        id: item.id,
        name: item.name,
        size: item.size.toString(),
        lastModifiedDateTime: item.lastModifiedDateTime,
      });
    });
    let requestObject = {
      P_SharePointSite_ID: siteId,
      P_UserAccessToken: !isTeamsApp() ? accessToken : localStorage.getItem("sharePointTeamsToken") ,
      P_Blob_Container_Name: createProjectResponse.project_UUID,
      P_Sharepoint_FileList: selectedSharepointDocuments,
      P_UploadUserEmail: localStorage.getItem("accountEmail"),
      P_UploadUserName: localStorage.getItem("accountName"),
    };
    // const res = await documentservice.uploadLocalDocuments(selectedDcoument,createProjectResponse.project_UUID,fullFilename,userMailID,userData);
    const res = await documentservice.UploadDocumentToSharePoint(requestObject, createProjectResponse?.project_UUID, fullFilename, userMailID, userData);
    if (res.status == 200) {
      setIsDocumentUploadProcessInitiated(true);
      // localFileUploadSuccess(true);
      sharePointFileUploadSuccess(true);
      setOpenModal(!openShow);
    } else {
      // localFileUploadSuccess(false);
      sharePointFileUploadSuccess(false);
      setOpenModal(!openShow);
    }
  }
  setShowLoader(false);
    }
    setIsUploadSubmitted(false);
  };

  function sharepointFileValidation(files) {
    let isspecialcharvalid = false;
    let isdupName=false;
    let isPerFileLimitExceed = false;
    let isShareFileValid=true;
    files.forEach((file) => {
      let fileExtension = file?.name.lastIndexOf(
        utils.getFileExtension(file?.name)
      );
      let filestringName = file?.name
        .toString()
        .substring(0, fileExtension - 1);
      if (utils.isContainSpecialCharacters(filestringName)) {
        isspecialcharvalid = true;
        chkFileValid = true;
      }
      filescountInfo.uploadedFilesdata?.forEach((item) => {
        if (item.documentName === file.name && !isspecialcharvalid) { 
          isdupName=true;
          chkFileValid = true;
        }
      });
    isPerFileLimitExceed ||= file.size > fileUploadLimit;
    const sharePointAllowedExtensions = ['pdf', 'docx', 'xlsx','xls', 'txt', 'pptx','ppt'];
    if (file?.name) {
    const SharePointFileExtension = file.name?.substring(file.name?.lastIndexOf('.')+1)?.toLowerCase();
    if (!sharePointAllowedExtensions.includes(SharePointFileExtension)) {
    isShareFileValid = false;
    }
    }
    });
    setspFileFormatValidation(isShareFileValid);
    setspFileValidation(isspecialcharvalid);
    setSharePointConfirmButtonEnabled(isspecialcharvalid);
    setspdupNameValidation(isdupName);
    setPerFileLimitExceed(isPerFileLimitExceed);
    setSharePointConfirmButtonEnabled(isPerFileLimitExceed);
    if(files.length===0){
      setSharePointConfirmButtonEnabled(true);
    }
  }
  return (
    <>
    {showLoader && <LoaderComponent />}
      <MotifModal
        label="modalDocumentUpload"
        className={`${isRightSideBarOpen?"modal-chatbar-open":"modal-chatbar-close"} motifModalupload ${isSideBarOpen?"modal-datasource-open":"modal-datasource-close"} ${isTeamsApp() ?"teams-app-upload-modal":""}`}
        show={setOpenModal}
        // focusTrapOptions={{
        //   tabbableOptions: {
        //     displayCheck: "legacy-full",
        //   },
        // }}
        onClose={() => {
          setOpenModal(!openShow);
        }}
      >
        <MotifModalHeader
          closeButtonProps={{
            "aria-label": "Custom Close Button aria-label",
            title: "Custom Close Button title",
          }}
        >
          Upload document
        </MotifModalHeader>
        <MotifModalBody>
          <div>
            <MotifFormField>
              <MotifFileUploader
                width
                className="uploaderdiv"
                id="my-id"
                icons={{upload:<img src={byodUploadIcon}/>}}
                label="Click to upload
or drag and drop
PDF, PPTX, DOC, XLS or TXT"
                onDrop={handleFileUpload}
              />

              <div className="ContentDivider1">
                <div className="Divider1" />
                <div className="Text1">Or</div>
                <div className="Divider1" />
              </div>

              <div className="DefaultInputFieldBase1">
                <div className="InputWithLabel DefaultInputFieldBase1">
                  <div className="Label1 sharepoint-title">Upload from SharePoint</div>
                  <div className="Frame53841">
                    <div
                      className="customCss1"
                      // style={{
                      //   width: "100%",
                      //   display: "flex",
                      //   gap: "10px",
                      // }}
                    >
                      <MotifFormField
                        className="customCss2"
                        // style={{ display: "flex", width: "100%", gap: "9px" }}
                      >
                        <div className="sharpointInput customCss3" 
                          // style={{ width: "90%" }}
                        >
                          <MotifInput
                          id="txtSharepoint"
                            clearButtonTitle="clear"
                            value={sharePointSiteUrl}
                            placeholder="Add SharePoint Link"
                            label="txtSharepoint"
                            type='text'
                            onChange={(e) => changeSiteUrlValue(e.target.value)}
                            className={
                              isInValidSiteUrl ? "motif-input-invalid" : ""
                            }
                          />
                          <div className= {isInValidSiteUrl||isInValidSharePointSite||isInValidSitePath||isSharePointValidLink?"sharepointLinkIcon2":"sharepointLinkIcon1" }><img src={link}/></div>
                          {isInValidSiteUrl && (
                            <MotifErrorMessage>
                              The link is invalid. Please try again.
                            </MotifErrorMessage>
                          )}
                          {isInValidSharePointSite && (
                            <MotifErrorMessage>
                              You do not have the required permissions to access
                              this link.
                            </MotifErrorMessage>
                          )}
                          {isInValidSitePath && (
                            <MotifErrorMessage>
                              You do not have the required permissions to access
                              this link.
                            </MotifErrorMessage>
                          )}
                          {isSharePointValidLink && (
                            <MotifInlineMessage variant="success">
                              This Link is valid.
                            </MotifInlineMessage>
                          )}
                        </div>

                        <div className="proceedbtn1">
                          <MotifButton onClick={showSharePointView}>
                            Proceed
                          </MotifButton>
                        </div>
                      </MotifFormField>
                    </div>
                  </div>
                </div>
              </div>
            </MotifFormField>
          </div>
        </MotifModalBody>
      </MotifModal>

      <MotifModal
        label="modalLocalUpload"
        className={`${isRightSideBarOpen?'motif-second-upload-modal-chatbaropen':'motif-second-upload-modal-chatbarclose'} motifModaluploadDoc`}
        show={openFilesGrid}
        onClose={() => {
          setOpneFilesGrid(false);
          setOpenModal(!openShow);
        }}
      >
        <MotifModalHeader
          closeButtonProps={{
            "aria-label": "Custom Close Button aria-label",
            title: "Custom Close Button title",
          }}
        >
          Uploading from Desktop
        </MotifModalHeader>
        <MotifModalBody>
          <div className="Frame534013">
            <div className="Frame54261">
              <div className="Table1 table12">
                <div className="CardHeader1">
                  <div className="CardTitle1">
                    <div className="TextAndSupportingText1">
                      <div className="text12">
                        <div className="Text13">
                          {totalFiles} Files of {totalFilesSize} MB Size
                          selected, Please review your selection
                        </div>
                      </div>
                      <div className="SupportingText1">
                        Select up to {MaxFileFlimit} files, with a total size limit of {utils.getGBFormat(MaxFileFlimitSize)} GB and a maximum size of 40 MB per file.{" "}
                        <span className={"customCss4"}>
                          {" "}
                          { errorfile && errrduplicate ?
                            "Incorrect File Format Selected, Duplicate file upload Selected."
                            : errorfile 
                            ? "Incorrect File Format Selected."
                            : errorFilescount
                            ? filecountLimit + " extra files Selected."
                            : errorFilseSizeCount
                            ? extrasize + "MB extra File Size Selected."
                            : errrduplicate
                            ? " Duplicate file upload Selected."
                            : errorCharacters
                            ? " Special Character File name Selected."
                            : perFileLimitExceed
                            ? `Per file Limit exceeded (${utils.getBytesInMB(
                                fileUploadLimit
                              )} MB)`
                            : " "}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="CardActions1">
                    <div className="Button1 remove-selected">
                      <MotifButton
                        className={
                          errorfile ||
                          errorFilescount ||
                          errrduplicate ||
                          errorCharacters ||
                          perFileLimitExceed
                            ? "btnRed"
                            : "btnBlock"
                        }
                        onClick={removeSelectedItems}
                      >
                        Remove Selected{" "}
                      </MotifButton>
                    </div>
                    <div className="Button2">
                    <img className="searchIcon" src={searchIcon} alt="search icon" />
                    
                        <MotifSearch
                          aria-label="Search"
                          hideClearButton
                          id="Local-search"
                          onChange={filterLocalFiles}
                          placeholder="Search"
                          className="searchTxtBox"
                          value={SearchLocal}
                        />
                    
                    </div>
                  </div>
                </div>

                <div
                  className={"ag-theme-quartz uploadconfirmTable customCss5"}
                  // style={{ width: "1000px", height: "auto" }}
                >
                  <MotifTable
                    rowData={selectedFile}
                    columnDefs={colDefs}
                    suppressCellFocus
                    suppressRowClickSelection
                    rowSelection="multiple"
                    //title="Motif table example"
                    onGridReady={(event) => onGridReady(event)}
                    onRowSelected={(event) => onRowSelection(event)}
                    pagination
                    paginationPageSize={pagesize}
                    paginationSelect={
                      <MotifPaginationSelect>
                        <MotifPaginationSelectItem
                          onClick={function noRefCheck() {
                            SetPageSize(5);
                          }}
                          selected
                        >
                          Show 5
                        </MotifPaginationSelectItem>
                        <MotifPaginationSelectItem
                          onClick={function noRefCheck() {
                            SetPageSize(10);
                          }}
                        >
                          Show 10
                        </MotifPaginationSelectItem>
                        <MotifPaginationSelectItem
                          onClick={function noRefCheck() {
                            SetPageSize(15);
                          }}
                        >
                          Show 15
                        </MotifPaginationSelectItem>
                      </MotifPaginationSelect>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </MotifModalBody>
        <MotifFooter>
          <div className="customCss6" 
            // style={{ display: "inline-flex", float: "right", gap: "10px" }}
          >
            <div className="SupportingText1">
            Please Select upto {MaxFileFlimit} files.{utils.getGBFormat(MaxFileFlimitSize)} GB
            </div>
            <MotifButton
              onClick={() => {
                setOpenModal(!openShow);
              }}
              size="medium"
              type="button"
              variant="secondary"
            >
              Cancel
            </MotifButton>
            {showLoader && <LoaderComponent />}
            <MotifButton
              disabled={
                isUploadSubmitted ||
                errorfile ||
                errorFilescount ||
                errorFilseSizeCount ||
                !selectedFile.length > 0 ||
                errrduplicate ||
                errorCharacters ||
                perFileLimitExceed
                  ? true
                  : false
              }
              onClick={onSumbitupload}
              size="medium"
              type="button"
            >
              Confirm
            </MotifButton>
          </div>
        </MotifFooter>
      </MotifModal>

      <MotifModal
        label="modalSharepointUpload"
        className="sharepoint-modal"
        show={showSharePointModal}
        // focusTrapOptions={{
        //   tabbableOptions: {
        //     displayCheck: "legacy-full",
        //   },
        // }}
        onClose={() => {
          setOpenModal(!openShow);
        }}
      >
        <MotifModalHeader
          closeButtonProps={{
            "aria-label": "Custom Close Button aria-label",
            title: "Custom Close Button title",
          }}
        >
          Uploading from SharePoint - {decodeURIComponent(getFirstFolderName(sitePath))}
        </MotifModalHeader>
        <MotifModalBody>
          {showDirectory && (
             <div
             className={"ag-theme-quartz uploadconfirmTable customCss7"}
            //  style={{ width: "1000px", height: "auto" }}
           >
              <MotifTable
                rowData={sharePointFiles}
                columnDefs={colDefsSharePointGrid}
                suppressCellFocus
                suppressRowClickSelection
                rowSelection="multiple"
                pagination
                paginationPageSize="4"
               // onGridReady={(event) => onGridReady(event)}
                onRowSelected={(event) => {
                  let selectedFiles = event.api.getSelectedRows();
                  setSelectedSharePointFiles(selectedFiles);
                 sharepointFileValidation(selectedFiles);
                  let selectedFilesCount = selectedFiles.length;
                  setTotalFiles(
                    filescountInfo?.filesuploadcount + selectedFilesCount
                  );
                  if (
                    filescountInfo?.filesuploadcount + selectedFilesCount >
                    MaxFileFlimit
                  ) {
                    setSharePointConfirmButtonEnabled(true);
                  } else {
                    if (!chkFileValid && selectedFilesCount !==0)
                      setSharePointConfirmButtonEnabled(false);
                    else
                    setSharePointConfirmButtonEnabled(true);
                  }
                  let selectedSize =
                    filescountInfo?.totalFileSize +
                    _.sumBy(selectedFiles, "size");

                  setTotalFilesSize(utils.getBytesInMB(selectedSize));
                  if (filescountInfo?.availableFilesizeLimit < selectedSize) {
                    seterrorFileSizeCount(true);
                    setExtraSize(
                      utils.getBytesInMB(
                        selectedSize - filescountInfo?.availableFilesizeLimit
                      )
                    );
                    //setSharePointConfirmButtonEnabled(true);
                  } else {
                    seterrorFileSizeCount(false);
                    setExtraSize("");
                    //setSharePointConfirmButtonEnabled(false);
                  }
                  let isdupName=false;
                  let isspecialcharvalid = false;
                  selectedFiles?.forEach((file) => {
                  filescountInfo.uploadedFilesdata?.forEach((item) => {
                    if (item.documentName === file.name) { 
                      isdupName=true;
                    }
                  });
                  let fileExtension = file?.name.lastIndexOf(
                    utils.getFileExtension(file?.name)
                  );
                  let filestringName = file?.name
                    .toString()
                    .substring(0, fileExtension - 1);
                  if (utils.isContainSpecialCharacters(filestringName)) {
                    isspecialcharvalid = true;
                  }
                })
                  if(filescountInfo?.MaxFilesuploadLimit<selectedFilesCount+ filescountInfo?.filesuploadcount){
                    setSharePointConfirmButtonEnabled(true);
                  
                  }
                  else if(errorfile||errorCharacters||perFileLimitExceed||!spFileFormatValidation|| isspecialcharvalid ||isdupName||selectedFilesCount===0||filescountInfo?.availableFilesizeLimit < selectedSize){
                    setSharePointConfirmButtonEnabled(true);
                  
                  }
                  else{
                    setSharePointConfirmButtonEnabled(false);
                  }
                }}
              >
                <MotifTableTitleBar variant="alt">
                  <div className="CardHeader1">
                    <div className="CardTitle1">
                      <div className="TextAndSupportingText1">
                        <div className="text12">
                          <div className="Text13">
                            {totalFiles} files of {totalFilesSize}MB size
                            selected, please review your selection.
                          </div>
                        </div>
                        <div className="SupportingText1">
                          Select upto {MaxFileFlimit} files within document size
                          limit of {MaxFileFlimitSize}
                          GB
                          {totalFiles > MaxFileFlimit && (
                            <span className="SupportingText1Danger">
                              {" "}
                              {totalFiles - MaxFileFlimit} extra files found.
                            </span>
                          )}
                             {perFileLimitExceed && (
                            <span className="SupportingText1Danger">
                              Per file Limit exceeded (
                              {utils.getBytesInMB(fileUploadLimit)} MB)
                            </span>
                            )}
                          {spFileValidation && (
                            <span className="SupportingText1Danger">
                              Special Character File name Selected.
                            </span>
                          )}
                           {spdupNameValidation && (
                            <span className="SupportingText1Danger">
                              Duplicate file upload Selected.
                            </span>
                          )}
                           {!spFileFormatValidation && (
                            <span className="SupportingText1Danger">
                              Incorrect File Format Selected.
                            </span>
                          )}
                          {errorFilseSizeCount && (
                          <span className="SupportingText1Danger">
                            {extrasize} MB extra File Size Selected.
                          </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="CardActions1">
                      <div className="Button2">
                        <MotifFormField>
                        <img className="sharePointSearchIcon" src={searchIcon} alt="sharePointSearchIcon"
                        />
                          <MotifSearch
                            aria-label="Search"
                            hideClearButton
                            id="sharepoint-search"
                            className="searchTxtBox"
                            onChange={filterSharePointFiles}
                            placeholder="Search"
                            value={sharePointSearch}
                          />
                        </MotifFormField>
                      </div>
                    </div>
                  </div>
                </MotifTableTitleBar>
              </MotifTable>
            </div>
          )}
        </MotifModalBody>
        <MotifFooter>
          <div
            className="customCss8"
            // style={{
            //   width: "100%",
            //   height: 48,
            //   background: "rgba(0, 0, 0, 0)",
            //   border: "1px rgba(0, 0, 0, 0) solid",
            //   justifyContent: "flex-end",
            //   alignItems: "center",
            //   gap: 12,
            //   display: "inline-flex",
            // }}
          >
            <div
              className="customCss9"
              // style={{
              //   justifyContent: "flex-end",
              //   alignItems: "center",
              //   gap: 12,
              //   display: "flex",
              // }}
            >
              <div
                className="customCss10"
                // style={{
                //   textAlign: "right",
                //   color: "#656579",
                //   fontSize: 14,
                //   fontFamily: "EYInterstate",
                //   fontWeight: "300",
                //   wordWrap: "break-word",
                // }}
              >
                Select upto {MaxFileFlimit} files within document size limit of{" "}
                {MaxFileFlimitSize} GB.{" "}
              </div>
              <MotifButton
                onClick={() => {
                  setOpenModal(!openShow);
                }}
                size="medium"
                type="button"
                variant="secondary"
                label="sharepointCancelButton"
              >
                Cancel
              </MotifButton>
              {showLoader && <LoaderComponent />}
              <MotifButton
                disabled={sharePointConfirmButtonEnabled || selectedSharePointFiles.length===0}
                // *** need to fix after Upload sharePoint APi implemented ***
                onClick={uploadSharePointDocument}
                // onClick={() => {
                //   setOpenModal(!openShow);
                // }}
                size="medium"
                type="button"
                label="sharepointConfirmButton"
              >
                Confirm
              </MotifButton>
            </div>
          </div>
        </MotifFooter>
      </MotifModal>
    </>
  );
};

export default UploadModal;
