import { useEffect, useState, useContext } from "react";
import "./AddDocument.css";
import _ from "lodash";
import { MotifButton } from "@ey-xd/motif-react";
import MotifToast from "@ey-xd/motif-react/components/toast";
import UploadModal from "./UploadModal";
import FilesUploadedGrid from "./UploadedFilesGrid";
// import { groupConfig } from "../../../config/groupConfig";
import upload from "../../assets/images/byod-images/upload.svg";
import DocumentService from "../../services/document.service";
import { DashboardContext } from "../../context/dashboardContext";
 import LoaderComponent from "../../components/motifComponents/LoaderComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IStore } from "../../store";
import { FileStatus } from "../../label.constant";
import { byodToastDoubleCheckIcon, byodToastloadIcon, byodToastloadInfoIcon } from "../../commonImage";
import toast from "@ey-xd/motif-react/components/toast";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import { setByodDocOpenClose } from "../../store/actions/commonActions";
import { saveUserDocumentThreadHistory, setLastDeletedThreadID } from "../../store/actions/userChatActions";
import { REACT_APP_POLLING_TIME_IN_SECOND } from "../../app.constant";
import { isTeamsApp } from "../../utils/commonUtils";

export function AddDocument({}) {
  const history = useNavigate();
   const documentservice = new DocumentService();
  const [showLoader, setShowLoader] = useState(false);
  const {
    isDocumentUploadProcessInitiated,
    setIsDocumentUploadProcessInitiated,
    showDocumentUploadMessage,
    setShowDocumentUploadMessage,
  }:any =  useContext(DashboardContext);
  const isRightSideBarOpen = useSelector((state: IStore) => state.common.isRightSideBarOpen); 
  const isSideBarOpen = useSelector((state: IStore) => state.common.isSideBarOpen);
  const isByodOpen = useSelector((state: IStore) => state.common.isByodDocOpen);
  const [show, setShow] = useState(false);
  const [fileCount, setFileCount] = useState(0);
  const [fileCountLimit, setFileCountLimit] = useState(0);
  const [uploadDisable, setuploadDisable] = useState(false);
  const [exploreDataDisable, setExploreDataDisable] = useState(true);
  const [fielsCount, setFilesCount] = useState({});
  const [fileFileLimitSize, setFileLimitSize] = useState(0);
  const [documentsHeader, setDocumentsHeader] = useState('Add documents');
  const [showUploadComplete, setShowUploadComplete] = useState(false);
  const [showUploadInPorgress, setShowUploadInPorgress] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [showUploadInitiated, setShowUploadInitiated] = useState(false);
  const [showErrorWhileLocalUpload, setShowErrorWhileLocalUpload] =
  useState(false);
  const [showUploadInitiatedSharepoint, setShowUploadInitiatedSharepoint] =
    useState(false);
  const [totalFielsIngrid, setTotalFilesInGrid] = useState(0);
  const [totalFielsSizeIngrid, setTotalFilesSizeInGrid] = useState(0);
  const [griddata, setgriddata] = useState([]);
  const documentThreads = useSelector((state: IStore) => state.userChat.documentThreadHistory);
  const [isUploadInProgressDisplayed, setIsUploadInProgressDisplayed] =
    useState(false);
    const selectedDocumentThread = useSelector(
      (state: IStore) => state.userChat.selectedDocumentThread
    );
    const userEmailId = useSelector((state: IStore) => state.common.userEmail);
    const lastDeletedThreadID = useSelector((state: IStore) => state?.userChat?.lastDeletedThreadID);
  let InititaData = [];
  const dispatch = useDispatch();
  // file validation details sending to uploadModal component
  function getFileLimits(filescount: number, totalfilessize: number, data: any): void {
    setTotalFilesInGrid(filescount);
    setTotalFilesSizeInGrid(totalfilessize);
    setgriddata(data);
    getDoucmentSettingsDetails(filescount, totalfilessize, data);
}

  useEffect(() => {
    // setSATUser(localStorage.getItem("groupName") == groupConfig.groupNameSat);
    setShow(show);
    dispatch(setByodDocOpenClose(false));
  }, []);
  useEffect(() => {
    if (lastDeletedThreadID != 0) {
      const updated = documentThreads.filter((item: any) => item.threadId !== lastDeletedThreadID);
      dispatch(saveUserDocumentThreadHistory(updated));
      dispatch(setLastDeletedThreadID(0));
    }
  }, [lastDeletedThreadID,documentThreads]);

  useEffect(() => {
    if (showDocumentUploadMessage && isUploadInProgressDisplayed) {
      setIsUploadInProgressDisplayed(false);
      setShowUploadInPorgress(true);
      setTimeout(() => {
        setShowUploadInPorgress(false);
      },3000)
    }
  }, [showDocumentUploadMessage]);
  useEffect(() => {
    if(selectedDocumentThread?.threadId){
      setDocumentsHeader('Manage documents');
    } else{
      setDocumentsHeader('Add documents');
    }
  },[selectedDocumentThread])
  useEffect(() => {
    //if (isDocumentUploadProcessInitiated) {
    if(selectedDocumentThread?.threadId){
    if (isDocumentUploadProcessInitiated) {
      let interval = setInterval(async()=> {
        const uploadDocuments:any =  await documentservice.getDocuments(
          selectedDocumentThread?.threadId,selectedDocumentThread?.title,userEmailId
        );
       
        if ( uploadedDocuments && uploadDocuments.length > 0) {
          setUploadedDocuments(uploadDocuments);
          const notCompletedDocs = _.filter(uploadDocuments, function (item) {
            return item.status === FileStatus.INPROGRESS;
          });
          if (notCompletedDocs.length > 0) {
            if (!showDocumentUploadMessage) {
              setShowDocumentUploadMessage(true);
              setIsUploadInProgressDisplayed(true);              
            }
            setShowUploadInitiatedSharepoint(false);
          } else {
            setShowUploadInitiatedSharepoint(false);
            setShowUploadComplete(true);
            setTimeout(() => {
              setShowUploadComplete(false);
            },3000)
            setExploreDataDisable(false);
            setIsDocumentUploadProcessInitiated(false);
            clearInterval(interval);
          }
        }
      }, 1000 * parseInt(REACT_APP_POLLING_TIME_IN_SECOND));
      return () => {
        clearInterval(interval);
      };
    }
  }
  else{
    setUploadedDocuments([]);
  }
  }, [selectedDocumentThread,isDocumentUploadProcessInitiated]);
  const initialLoadData = (data:any) => { // the callback.
    setUploadedDocuments(data);
    if(data && data.length>0){
      const notCompletedDocs = _.filter(data, function (item:any) {
      return item?.status === FileStatus.INPROGRESS || item?.status === FileStatus.ERROR;
    });
    if(data.length != notCompletedDocs.length)
    setExploreDataDisable(false);
    }
};

useEffect(() => {
  if(uploadedDocuments && uploadedDocuments.length>0){
    const notCompletedDocs = _.filter(uploadedDocuments, function (item:any) {
    return item?.status === FileStatus.INPROGRESS || item?.status === FileStatus.ERROR;
  });
  if(uploadedDocuments.length != notCompletedDocs.length)
  setExploreDataDisable(false);
  }
},[uploadedDocuments])


  
//}, [show, isDocumentUploadProcessInitiated]);
  const localFileUploadSubmitted = (isUploaded:any) => {
    if (isUploaded) {
      setShowUploadInitiated(true);
      setTimeout(() => {
        setShowUploadInitiated(false);
      },3000)
    }else {
      setShowErrorWhileLocalUpload(true);
      setTimeout(() => {
        setShowErrorWhileLocalUpload(false);
      },3000)
    }
  };

  const sharePointFileUploadSubmitted = (isUploaded:any) => {
    if (isUploaded) {
      setIsDocumentUploadProcessInitiated(true);
      setShowUploadInitiatedSharepoint(true);
    }
  };

  // get the dcoumentsetting configuration(limitaion of file count and size)
  async function getDoucmentSettingsDetails(filescount:any, totalfilessize:any, data:any) {
    interface initData {
        noOfDocuments: any;
        documentSize: any;
      }
      const InititaData:initData = await documentservice.getDocumentSettings(selectedDocumentThread?.threadId,selectedDocumentThread?.title,userEmailId);
    if (
        InititaData?.noOfDocuments !== "" &&
        InititaData?.noOfDocuments != null
    ) {
      setFileCountLimit(parseInt(InititaData.noOfDocuments));
      let filesizeGB = parseInt(InititaData.documentSize);
      let resBytes = filesizeGB * 1073741824;
      setFileLimitSize(resBytes);
      fileLimitValidationInfo(
        parseInt(InititaData.noOfDocuments),
        filescount,
        resBytes,
        totalfilessize,
        InititaData.documentSize,
        data
      );
    }
  }
  //prepare file validation to send uploadcomponent modal
  function fileLimitValidationInfo(
    fileLimit: any,
    totalfilecount: any,
    filzesizeLimit: any,
    totalfilesize: any,
    maxFileSize: any,
    data: any
  ) {
    if (fileLimit > totalfilecount) {
      let result = fileLimit - totalfilecount;
      let resultfilesize = filzesizeLimit - totalfilesize;
      setFileCount(result);
      setuploadDisable(false);
      let rescount = {
        avialbalefileuplaod: result,
        filesuploadcount: totalfilecount,
        MaxFilesuploadLimit: fileLimit,

        availableFilesizeLimit: resultfilesize,
        totalFileSize: totalfilesize,
        MaxFFileSizeLimit: maxFileSize,

        uploadedFilesdata: data,
      };
      setFilesCount(rescount);
    } else setuploadDisable(true);
  }
  const breadcrumbs = [{ label: 'Home' }, { label: 'Upload Document and Query', current: true }];

  return (
    <div className={`adddocuments_section ${isRightSideBarOpen?'chatbaropen':'docchatbarclosed'} ${isSideBarOpen?"datasourcebar-open":"datasourcebar-close"} ${isTeamsApp()?"teams-app":""} ${isByodOpen?"sourcedocs-open":"sourcedocs-close"}`}>
      <div className="progressmessage">
      {showUploadInPorgress && (
        <MotifToast
          variant="info"
          actionName=""
          position="top"
          icons={{toast:<img src={byodToastloadInfoIcon} alt="check" />,close:''}}
          onClose={() => {
            setShowUploadInPorgress(false);
          }}
        >
          <div>
            Upload complete. Getting your documents ready for prompt. You can now exit Competitive Edge.
          </div>
        </MotifToast>
      )}
      {showUploadComplete && (
        <MotifToast
          variant="success"
          actionName=""
          position="top"
          icons={{toast: <img src={byodToastDoubleCheckIcon} alt="check" />,close:''}}
          onClose={() => {
            setShowUploadComplete(false);
          }}
        >
          <div>
          Processing complete. Your documents are now ready for prompt.
          </div>
        </MotifToast>
      )}
      </div>

      {showLoader && <LoaderComponent />}
      <div className={`${uploadedDocuments && uploadedDocuments.length>0?"Frame123": "EmptyFrame123"}`}>
      <div className={isRightSideBarOpen ? 'breadcrumbOpen' : 'breadcrumb'}>
          <Breadcrumb items={breadcrumbs} />
        </div>
        <div
          className={
            griddata?.length > 0
              ? "Title12 title12Width"
              : "Title12 title1Width"
          }
        >
   
          <div className="TextAndSupportingText">
            <div className="Text2">
              { <div className="Text6">{documentsHeader}</div>}
            </div>
          </div>

          <div className="motif-button-content upload_explore_botton">
            {(
              <>
              <MotifButton
                disabled={uploadDisable}
                onClick={() => setShow(true)}
                className="motif-upload-doc-button"
              >
                <img src={upload} alt="upload document icon" />
                Upload document
              </MotifButton>

              <MotifButton
              disabled={exploreDataDisable}
              onClick={() => {history('/exploreData')}}
              className="motif-explore-doc-button"
              >
              Explore your data
            </MotifButton>
            </>
            )}
          </div>

          {show && (
            <UploadModal
              openShow={show}
              setOpenModal={setShow}
              filescountInfo={fielsCount}
              localFileUploadSuccess={localFileUploadSubmitted}
              sharePointFileUploadSuccess={sharePointFileUploadSubmitted}
            />
          )}
        </div>
        <FilesUploadedGrid
          fileCountcheck={getFileLimits}
          openShow={show}
          filesLimit={fileCountLimit}
          fileSizeLimit={fileFileLimitSize}
          documentList={uploadedDocuments}
          setinitialLoadData={initialLoadData}
        />
      </div>

     
      {showUploadInitiatedSharepoint && (
        <MotifToast
          variant="error"
          actionName=""
          position="bottom"
          onClose={() => {
            setShowUploadInitiatedSharepoint(false);
          }}
        >
          <div>
            Upload in progress. You will be notified when upload has been
            completed. Do not exit Competitive Edge.
          </div>
        </MotifToast>
      )}
      {showUploadInitiated && (
        <MotifToast
          variant="error"
          actionName=""
          position="bottom"
          icons={{toast:<img src={byodToastloadIcon} alt="check" />,close:''}}
          onClose={() => {
            setShowUploadInitiated(false);
          }}
        >
          <div>
            Upload in progress. You will be notified when upload has been
            completed. Do not exit Competitive Edge.
          </div>
        </MotifToast>
      )}
       {showErrorWhileLocalUpload && (
        <MotifToast
          variant="error"
          actionName=""
          position="bottom"
          onClose={() => {
            setShowErrorWhileLocalUpload(false);
          }}
        >
          <div>
            Something went wrong while uploading document. Please try again.
          </div>
        </MotifToast>
      )}
    </div>
  );
}

export default AddDocument;
